const DOCUMENT = 'TYPES_';

const typesActions = {

  GET_ASSIGNMENT_REQUEST: `${DOCUMENT}GET_ASSIGNMENT_REQUEST`,
  GET_ASSIGNMENT_SUCCESS: `${DOCUMENT}GET_ASSIGNMENT_SUCCESS`,
  GET_ASSIGNMENT_ERROR: `${DOCUMENT}GET_ASSIGNMENT_ERROR`,

  GET_ENGAGEMENT_REQUEST: `${DOCUMENT}GET_ENGAGEMENT_REQUEST`,
  GET_ENGAGEMENT_SUCCESS: `${DOCUMENT}GET_ENGAGEMENT_SUCCESS`,
  GET_ENGAGEMENT_ERROR: `${DOCUMENT}GET_ENGAGEMENT_ERROR`,

  GET_TECHNOLOGY_REQUEST: `${DOCUMENT}GET_TECHNOLOGY_REQUEST`,
  GET_TECHNOLOGY_SUCCESS: `${DOCUMENT}GET_TECHNOLOGY_SUCCESS`,
  GET_TECHNOLOGY_ERROR: `${DOCUMENT}GET_TECHNOLOGY_ERROR`,

  GET_FLAG_REQUEST: `${DOCUMENT}GET_FLAG_REQUEST`,
  GET_FLAG_SUCCESS: `${DOCUMENT}GET_FLAG_SUCCESS`,
  GET_FLAG_ERROR: `${DOCUMENT}GET_FLAG_ERROR`,

  GET_BONUS_REQUEST: `${DOCUMENT}GET_BONUS_REQUEST`,
  GET_BONUS_SUCCESS: `${DOCUMENT}GET_BONUS_SUCCESS`,
  GET_BONUS_ERROR: `${DOCUMENT}GET_BONUS_ERROR`,

  GET_PROJECT_STATUS_REQUEST: `${DOCUMENT}GET_PROJECT_STATUS_REQUEST`,
  GET_PROJECT_STATUS_SUCCESS: `${DOCUMENT}GET_PROJECT_STATUS_SUCCESS`,
  GET_PROJECT_STATUS_ERROR: `${DOCUMENT}GET_PROJECT_STATUS_ERROR`,

  GET_TIERS_REQUEST: `${DOCUMENT}GET_TIERS_REQUEST`,
  GET_TIERS_SUCCESS: `${DOCUMENT}GET_TIERS_SUCCESS`,
  GET_TIERS_ERROR: `${DOCUMENT}GET_TIERS_ERROR`,

  GET_WORKLOAD_REQUEST: `${DOCUMENT}GET_WORKLOAD_REQUEST`,
  GET_WORKLOAD_SUCCESS: `${DOCUMENT}GET_WORKLOAD_SUCCESS`,
  GET_WORKLOAD_ERROR: `${DOCUMENT}GET_WORKLOAD_ERROR`,

  GET_ALL: `${DOCUMENT}GET_ALL_REQUEST`,
  GET_ALL_SUCCESS: `${DOCUMENT}GET_ALL_SUCCESS`,
  GET_ALL_ERROR: `${DOCUMENT}GET_ALL_ERROR`,

  getAssignmentsRequest: () => ({
    type: typesActions.GET_ASSIGNMENT_REQUEST,
  }),

  getAssignmentsSuccess: (payload) => ({
    type: typesActions.GET_ASSIGNMENT_SUCCESS,
    payload,
  }),

  getAssignmentsError: () => ({
    type: typesActions.GET_ASSIGNMENT_ERROR,
  }),

  getEngagementRequest: () => ({
    type: typesActions.GET_ENGAGEMENT_REQUEST,
  }),

  getEngagementSuccess: (payload) => ({
    type: typesActions.GET_ENGAGEMENT_SUCCESS,
    payload,
  }),

  getEngagementError: () => ({
    type: typesActions.GET_ENGAGEMENT_ERROR,
  }),

  /* Get Technology Types */

  getTechnologyRequest: () => ({
    type: typesActions.GET_TECHNOLOGY_REQUEST,
  }),

  getTechnologySuccess: () => ({
    type: typesActions.GET_TECHNOLOGY_SUCCESS,
  }),

  getTechnologyError: () => ({
    type: typesActions.GET_TECHNOLOGY_ERROR,
  }),

  /* Get Flag Types */

  getFlagsRequest: () => ({
    type: typesActions.GET_FLAG_REQUEST,
  }),

  getFlagsSuccess: (payload) => ({
    type: typesActions.GET_FLAG_SUCCESS,
    payload,
  }),

  getFlagsError: () => ({
    type: typesActions.GET_FLAG_ERROR,
  }),

  /* Get Bonus Types */

  getBonusRequest: () => ({
    type: typesActions.GET_BONUS_REQUEST,
  }),

  getBonusSuccess: () => ({
    type: typesActions.GET_BONUS_SUCCESS,
  }),

  getBonusError: () => ({
    type: typesActions.GET_BONUS_ERROR,
  }),

  /* Get Bonus Types */

  getProjectStatusRequest: () => ({
    type: typesActions.GET_PROJECT_STATUS_REQUEST,
  }),

  getProjectStatusSuccess: (payload) => ({
    type: typesActions.GET_PROJECT_STATUS_SUCCESS,
    payload,
  }),

  getProjectStatusError: () => ({
    type: typesActions.GET_PROJECT_STATUS_ERROR,
  }),

  /* Get Tiers */

  getTiersRequest: () => ({
    type: typesActions.GET_TIERS_REQUEST,
  }),

  getTiersSuccess: payload => ({
    type: typesActions.GET_TIERS_SUCCESS,
    payload,
  }),

  getTiersError: () => ({
    type: typesActions.GET_TIERS_ERROR,
  }),

    /* Get Workload */

    getWorkloadRequest: () => ({
      type: typesActions.GET_WORKLOAD_REQUEST,
    }),

    getWorkloadSuccess: payload => ({
      type: typesActions.GET_WORKLOAD_SUCCESS,
      payload,
    }),

    getWorkloadError: () => ({
      type: typesActions.GET_WORKLOAD_ERROR,
    }),

  /** GET ALL TYPES */

  getAllTypesRequest: () => ({
    type: typesActions.GET_ALL,
  }),

  getAllTypeSuccess: () => ({
    type: typesActions.GET_ALL,
  }),

  getAllTypeError: () => ({
    type: typesActions.GET_ALL,
  }),
};

export default typesActions;
