import actions from 'redux/projects/actions';

export const PAGE_SIZE_LOCALSTORAGE = 'ProjectsList_PageSize';

const getInitState = () => ({
  loading: false,
  isModalActive: false,
  projectsList: [],
  resources: [],
  projectsSimpleList: [],
  simpleListLoading: false,
});

export default function projectsReducer(state = getInitState(), action) {
  const { type, payload } = action;
  const { projectsList, projectsSimpleList, resources } = payload || [];

  switch (type) {
    case actions.GET_INITIAL_PROJECTS_LIST_STATE:
      return {
        ...state,
        loading: true,
      };

    case actions.GET_INITIAL_PROJECTS_LIST_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        ...payload,
      };

    case actions.GET_INITIAL_PROJECTS_LIST_STATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case actions.SET_INITIAL_PROJECTS_LIST_STATE:
      return {
        ...state,
        ...payload,
        loading: true
      };

    case actions.GET_PROJECTS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_PROJECTS_LIST_SUCCESS:
      return {
        ...state,
        projectsList,
        loading: false,
      };

    case actions.GET_PROJECTS_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };

    case actions.GET_PROJECTS_SIMPLE_LIST_REQUEST:
      return {
        ...state,
        simpleListLoading: true,
      };
    case actions.GET_PROJECTS_SIMPLE_LIST_SUCCESS:
      return {
        ...state,
        projectsSimpleList,
        simpleListLoading: false,
      };

    case actions.GET_PROJECTS_SIMPLE_LIST_ERROR:
      return {
        ...state,
        simpleListLoading: false,
      };

    case actions.GET_ONGOING_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_ONGOING_PROJECTS_SUCCESS:
      return {
        ...state,
        projectsList,
        loading: false,
      };

    case actions.GET_ONGOING_PROJECTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case actions.GET_TIMELINE_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_TIMELINE_PROJECTS_SUCCESS:
      return {
        ...state,
        projectsList,
        loading: false,
      };

    case actions.GET_TIMELINE_PROJECTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case actions.GET_PROJECTS_BY_RESOURCES_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_PROJECTS_BY_RESOURCES_HISTORY_SUCCESS:
      return {
        ...state,
        projectsList,
        loading: false,
      };
    case actions.GET_PROJECTS_BY_RESOURCES_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
      };

    case actions.GET_PROJECTS_BY_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actions.GET_PROJECTS_BY_TYPE_SUCCESS:
      return {
        ...state,
        projectsList,
        resources,
        loading: false,
      };

    case actions.GET_PROJECTS_BY_TYPE_CHANGED_SUCCESS:
      return {
        ...state,
        projectsList: state.projectsList.map(
          item => projectsList.find(project => project.id === item.id) || item
        ),
        loading: false,
      };

    case actions.DELETE_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actions.DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        projectsList: state.projectsList.filter(item => item.id !== action.payload.id),
        loading: false,
      };

    case actions.DELETE_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case actions.ADD_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actions.ADD_PROJECT_SUCCESS:
      return {
        ...state,
        isModalActive: !state.isModalActive,
        projectsList: [
          ...state.projectsList,
          payload.project,
        ],
        loading: false,
      };

    case actions.ADD_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case actions.TOGGLE_MODAL:
      return {
        ...state,
        isModalActive: !state.isModalActive,
      };

    case actions.UPDATE_FILTERS:
      return {
        ...state,
        loading: true
      };

    case actions.UPDATE_SORTING:
      return {
        ...state,
        sort: payload,
      };

    case actions.MOVE_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actions.MOVE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case actions.MOVE_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case actions.RESET_STATE:
      return {
        ...getInitState()
      };

    case actions.EXPORT_DATA:
      return {
        ...state,
      }

    default:
      return state;
  }
};
