import resourcesActions from 'redux/resources/resource/actions';
import { generateEditableView, removeSpinByAll, addSpinByAll } from 'redux/resources/helpers';
import actions from '../../app/actions'

const initialResource = {
  firstName: '',
  lastName: '',
  avatar: '',
  id: null,
  location: {},
  hubstaffLink: '',
  cvLink: '',
  githubLink: '',
  email: '',
  remote: null,
  phone: '',
  skype: '',
  lastEvaluationDate: null,
  lastEnglishEvaluationDate: '',
  isPartTime: false,
  isEvaluationNeeded: false,
  jiraLink: '',
  deletedAt: '',
  potentialProjectId: '',
  assignmentTypeId: null,
  resourceRoleId: null,
  defaultAssignmentTypeId: '',
  resourceRole: {
    id: null,
    title: '',
    color: '',
    deletedAt: null,
  },
  englishLevelId: '',
  englishLevel: {
    id: null,
    value: '',
    deletedAt: null,
  },
  techLevelId: '',
  techLevel: {
    id: null,
    value: '',
    deletedAt: null,
  },
  projects: [],
  assignmentType: {
    id: null,
    title: '',
    color: '',
    deletedAt: null,
  },
  technologies: [],
  bonus: [],
  fileUrl: '',
  file: null,
  contractStartDate: '',
  contractEndDate: '',
  paidVacationDays: 0,
  unpaidVacationDays: 0,
  paidVacationDaysLeft: 0,
  unpaidVacationDaysLeft: 0,
  futureProjectsList: [],
  projectsList: [],
  signingBonus: {
    id: null,
    status: '',
    amount: 0,
  },
};

const getInitialState = () => ({
  resource: initialResource,
  isModalActive: false,
  editView: {
    info: {
      status: false,
      loading: false,
    },
    technologies: {
      status: false,
      loading: false,
    },
    projects: {
      status: false,
      loading: false,
    },
    contacts: {
      status: false,
      loading: false,
    },
    other: {
      status: false,
      loading: false,
    },
    vacation: {
      status: false,
      loading: false,
    },
    hire: {
      status: false,
      loading: false,
    },
    contract: {
      status: false,
      loading: false,
    },
    resourceEvaluations: {
      status: false,
      loading: false,
    },
    resourceHistory: {
      status: false,
      loading: false,
    },
    projectHistory: {
      status: false,
      loading: false,
    },
    salary: {
      status: false,
      loading: false,
    },
    signingBonus: {
      status: false,
      loading: false,
    },
    referralBonus: {
      status: false,
      loading: false,
    },
    comments: {
      status: false,
      loading: false,
    },
  },
  localData: {
    projects: [],
    potentialProject: null,
  },
  fileUrl: '',
  file: null,
  loading: false,
  contract: {
    data: [],
    loading: false,
  },
  resourceEvaluations: {
    data: [],
    loading: false,
  },
  resourceTechnologyEvaluations: {
    data: [],
    loading: false,
  },
  resourceEnglishEvaluations: {
    data: [],
    loading: false,
  },
  resourceHistory: {
    data: [],
    loading: false,
  },
  salary: {
    data: [],
    loading: false,
  },
  signingBonus: {
    data: [],
    loading: false,
  },
  overtime: {
    data: [],
    loading: false,
  },
  referralBonus: {
    data: [],
    loading: false
  },
  comments: {
    data: [],
    loading: false,
  },
  resources: {
    list: [],
    pagination: {},
    loading: false,
  },
})

export default function ResourceReducer(state = getInitialState(), action) {
  const { type, payload } = action;
  switch (type) {

    // View change
    case resourcesActions.CHANGE_VIEW:
      return {
        ...state,
        editView: {
          ...state.editView,
          [payload.modalName]: {
            ...state.editView[payload.modalName],
            status: payload.status,
          },
        },
      };

    // Upload avatar
    case resourcesActions.UPLOAD_AVATAR_SUCCESS:
      const { fileUrl, file } = payload;
      return {
        ...state,
        profile: {
          ...state.profile,
        },
        file,
        fileUrl,
        loading: false,
      };
    case resourcesActions.UPLOAD_AVATAR_ERROR:
      return {
        ...state,
        error: payload.error,
      };

    case resourcesActions.GET_RESOURCE_VACATION_REQUEST:
      return {
        ...state,
        editView: {
          ...state.editView,
          vacation: {
            status: false,
            loading: true,
          }
        }
      }

    case resourcesActions.GET_RESOURCE_VACATION_SUCCESS:
      return {
        ...state,
        resource: {
          ...state.resource,
          ...payload,
        },
        editView: {
          ...state.editView,
          vacation: {
            status: false,
            loading: false,
          }
        }
      }

    case resourcesActions.GET_RESOURCE_VACATION_ERROR:
      return {
        ...state,
        editView: {
          ...state.editView,
          vacation: {
            status: false,
            loading: false,
          }
        }
      }

    // Edit profile request
    case resourcesActions.EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case resourcesActions.EDIT_SUCCESS:
      return {
        ...generateEditableView(state, payload.resource),
        editView: {
          ...state.editView,
          [payload.modalName]: {
            status: false,
            loading: false,
          },
        },
      };

    case resourcesActions.EDIT_ERROR:
      return {
        ...state,
        error: payload.error,
        ...removeSpinByAll(state),
      };

    // Get resource request
    case resourcesActions.GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case resourcesActions.GET_SUCCESS:
      return {
        ...generateEditableView(state, payload),
        file: initialResource.file,
        fileUrl: initialResource.fileUrl,
      };

    case resourcesActions.GET_ERROR:
      return {
        ...state,
        error: payload.error,
        ...removeSpinByAll(state),
        loading: false,
      };
    // add TECHNOLOGY
    case resourcesActions.UPDATE_TECHNOLOGIES_REQUEST:
      return {
        ...state,
        editView: {
          ...state.editView,
          technologies: {
            ...state.editView.technologies,
            loading: true,
          },
        },

      };
    case resourcesActions.UPDATE_TECHNOLOGIES_SUCCESS:
      return {
        ...state,
        resource: {
          ...state.resource,
          technologies: [...payload.technologies]
        },
        editView: {
          ...state.editView,
          technologies: {
            ...state.editView.technologies,
            loading: false,
          },
        },
      };

    case resourcesActions.UPDATE_TECHNOLOGIES_ERROR:
      return {
        ...state,
        editView: {
          ...state.editView,
          technologies: {
            ...state.editView.technologies,
            loading: false,
          },
        },
      };

    // add Initial State

    case resourcesActions.GET_INITIAL_REQUEST:
      return {
        ...state,
        ...addSpinByAll(state),
      };
    case resourcesActions.GET_INITIAL_SUCCESS:
      return generateEditableView(state, payload);

    case resourcesActions.GET_INITIAL_ERROR:
      return {
        ...state,
        loading: false,
      };
    case resourcesActions.SUBMIT_UPDATES:
      return {
        ...state,
        editView: {
          ...state.editView,
          [payload.modalName]: {
            ...state.editView[payload.modalName],
            loading: true,
          },
        },
      };

    // UPDATE RESOURCE PROJECT

    case resourcesActions.UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        editView: {
          ...state.editView,
          projects: {
            ...state.editView.projects,
            loading: true,
          },
        },
      };

    case resourcesActions.UPDATE_PROJECT_SUCCESS:
      const { projects, potentialProjectId, } = payload;

      return {
        ...state,
        resource: {
          ...state.resource,
          projects,
          potentialProjectId,
        },
        editView: {
          ...state.editView,
          projects: {
            ...state.editView.projects,
            loading: false,
          },
        },
        loading: false,
      };

    // RESET SELECTED FILE
    case resourcesActions.RESET_FILE_STATE:
      return {
        ...state,
        fileUrl: '',
        file: null,
      };

    // Get resource contracts request
    case resourcesActions.GET_RESOURCE_CONTRACTS_REQUEST:
    case resourcesActions.ADD_RESOURCE_CONTRACTS_REQUEST:
    case resourcesActions.REMOVE_RESOURCE_CONTRACTS_REQUEST:
      return {
        ...state,
        contract: {
          ...state.contract,
          loading: true,
        },
      };
    case resourcesActions.GET_RESOURCE_CONTRACTS_SUCCESS:
      return {
        ...state,
        contract: {
          data: payload,
          loading: false,
        },
      };

    case resourcesActions.GET_RESOURCE_CONTRACTS_ERROR:
      return {
        ...state,
        loading: false,
      }

    case resourcesActions.GET_RESOURCE_EVALUATIONS_REQUEST:
      return {
        ...state,
        resourceEvaluations: {
          ...state.resourceEvaluations,
          loading: true,
        },
      };
    case resourcesActions.GET_RESOURCE_EVALUATIONS_SUCCESS:
      return {
        ...state,
        resourceEvaluations: {
          data: payload,
          loading: false,
        },
      };
    case resourcesActions.GET_RESOURCE_EVALUATIONS_ERROR:
      return {
        ...state,
        loading: false,
      }

    case resourcesActions.GET_RESOURCE_TECHNOLOGY_EVALUATIONS_REQUEST:
      return {
        ...state,
        resourceTechnologyEvaluations: {
          ...state.resourceTechnologyEvaluations,
          loading: true
        }
      }

    case resourcesActions.GET_RESOURCE_TECHNOLOGY_EVALUATIONS_SUCCESS:
      return {
        ...state,
        resourceTechnologyEvaluations: {
          data: payload,
          loading: false,
        }
      }

    case resourcesActions.GET_RESOURCE_TECHNOLOGY_EVALUATIONS_ERROR:
      return {
        ...state,
        resourceTechnologyEvaluations: {
          ...state.resourceTechnologyEvaluations,
          loading: false
        }
      }

    case resourcesActions.GET_RESOURCE_ENGLISH_EVALUATIONS_REQUEST:
      return {
        ...state,
        resourceEnglishEvaluations: {
          ...state.resourceEnglishEvaluations,
          loading: true
        }
      }

    case resourcesActions.GET_RESOURCE_ENGLISH_EVALUATIONS_SUCCESS:
      return {
        ...state,
        resourceEnglishEvaluations: {
          data: payload,
          loading: false,
        }
      }

    case resourcesActions.GET_RESOURCE_ENGLISH_EVALUATIONS_ERROR:
      return {
        ...state,
        resourceEnglishEvaluations: {
          ...state.resourceEnglishEvaluations,
          loading: false
        }
      }

    case resourcesActions.GET_RESOURCE_HISTORY_DETAIL_PAGE_REQUEST:
      return {
        ...state,
        resourceHistory: {
          ...state.resourceHistory,
          loading: true,
        },
      }
    case resourcesActions.GET_RESOURCE_HISTORY_DETAIL_PAGE_SUCCESS:
      return {
        ...state,
        resourceHistory: {
          data: payload,
          loading: false,
        },
      };
    case resourcesActions.GET_RESOURCE_HISTORY_DETAIL_PAGE_ERROR:
      return {
        ...state,
        loading: false,
      }

    case resourcesActions.ADD_RESOURCE_CONTRACTS_ERROR:
    case resourcesActions.REMOVE_RESOURCE_CONTRACTS_ERROR:
    case resourcesActions.UPDATE_RESOURCE_CONTRACT_ERROR:
      return {
        ...state,
        error: payload.error,
        ...removeSpinByAll(state),
        contract: {
          ...state.contract,
          loading: false,
        },
      };

    // Add resource contracts request
    case resourcesActions.ADD_RESOURCE_CONTRACTS_SUCCESS:
      return {
        ...state,
        contract: {
          data: payload,
          loading: false,
        },
      };

    // Remove resource contracts request
    case resourcesActions.REMOVE_RESOURCE_CONTRACTS_SUCCESS:
      return {
        ...state,
        contract: {
          data: state.contract.data.filter(c => c.id !== payload),
          loading: false,
        },
      };

    // Update resource contract request
    case resourcesActions.UPDATE_RESOURCE_CONTRACT_REQUEST:
      return {
        ...state,
        contract: {
          ...state.contract,
          loading: true,
        },
      };

      // Update resource contract success
    case resourcesActions.UPDATE_RESOURCE_CONTRACT_SUCCESS:
      return {
        ...state,
        contract: {
          ...state.contract,
          loading: false,
          data: payload,
        },
      };

    // Get/Add resource salary
    case resourcesActions.GET_RESOURCE_SALARY_REQUEST:
    case resourcesActions.ADD_RESOURCE_SALARY_REQUEST:
    case resourcesActions.UPDATE_RESOURCE_SALARY_REQUEST:
      return {
        ...state,
        salary: {
          ...state.salary,
          loading: true,
        },
      };

    case resourcesActions.GET_RESOURCE_SALARY_SUCCESS:
    case resourcesActions.ADD_RESOURCE_SALARY_SUCCESS:
      return {
        ...state,
        salary: {
          data: payload,
          loading: false,
        },
      };

    // Remove resource salary
    case resourcesActions.REMOVE_RESOURCE_SALARY_SUCCESS:
      return {
        ...state,
        salary: {
          data: state.salary.data.filter(salary => salary.id !== payload),
          loading: false,
        },
      };

    // Update salary item
    case resourcesActions.UPDATE_RESOURCE_SALARY_SUCCESS:
      const salaries = [...state.salary.data];
      const salaryId = salaries.findIndex(item => {
        return item.id === payload.id;
      });
      salaries[salaryId] = payload;

      return {
        ...state,
        salary: {
          ...state.salary,
          data: salaries,
          loading: false,
        },
      };

    // Salary requests error
    case resourcesActions.SALARY_REQUESTS_ERROR:
      return {
        ...state,
        salary: {
          ...state.salary,
          loading: false,
        },
      };

    // Get/Add resource signing bonus
    case resourcesActions.GET_RESOURCE_SIGNING_BONUS_REQUEST:
    case resourcesActions.ADD_RESOURCE_SIGNING_BONUS_REQUEST:
    case resourcesActions.UPDATE_RESOURCE_SIGNING_BONUS_REQUEST:
      return {
        ...state,
        signingBonus: {
          ...state.signingBonus,
          loading: true,
        },
      };

    case resourcesActions.GET_RESOURCE_SIGNING_BONUS_SUCCESS:
    case resourcesActions.ADD_RESOURCE_SIGNING_BONUS_SUCCESS:
      return {
        ...state,
        signingBonus: {
          data: payload,
          loading: false,
        },
      };

    // Remove resource signing bonus
    case resourcesActions.REMOVE_RESOURCE_SIGNING_BONUS_SUCCESS:
      return {
        ...state,
        signingBonus: {
          data: state.signingBonus.data.filter(bonus => bonus.id !== payload),
          loading: false,
        },
      };

    // Update signing bonus item
    case resourcesActions.UPDATE_RESOURCE_SIGNING_BONUS_SUCCESS:
      const signingBonuses = [...state.signingBonus.data];
      const changedBonusId = signingBonuses.findIndex(item => {
        return item.id === payload.id;
      });
      signingBonuses[changedBonusId] = payload;

      return {
        ...state,
        signingBonus: {
          ...state.signingBonus,
          data: signingBonuses,
          loading: false,
        },
      };

    // Signing bonus requests error
    case resourcesActions.SIGNING_BONUS_REQUESTS_ERROR:
      return {
        ...state,
        signingBonus: {
          ...state.signingBonus,
          loading: false,
        },
      };

    // Resource referral bonus
    case resourcesActions.GET_RESOURCE_REFERRAL_BONUS_REQUEST:
    case resourcesActions.ADD_RESOURCE_REFERRAL_BONUS_REQUEST:
    case resourcesActions.UPDATE_RESOURCE_REFERRAL_BONUS_REQUEST:
      return {
        ...state,
        referralBonus: {
          ...state.referralBonus,
          loading: true,
        }
      };

      // Closing edit mode after navigating to another page
    case actions.CHANGE_CURRENT:
      return {
        ...state,
        editView: {
          ...state.editView,
          info: {
            ...state.editView.info,
            status: false,
          },
          technologies: {
            ...state.editView.technologies,
            status: false,
          },
          projects: {
            ...state.editView.projects,
            status: false,
          },
          other: {
            ...state.editView.other,
            status: false,
          },
          vacation: {
            ...state.editView.vacation,
            status: false,
          },
          contacts: {
            ...state.editView.contacts,
            status: false,
          },
          hire: {
            ...state.editView.hire,
            status: false,
          },
          contract: {
            ...state.editView.contract,
            status: false,
          },
          salary: {
            ...state.editView.salary,
            status: false,
          },
          signingBonus: {
            ...state.editView.signingBonus,
            status: false,
          },
          overtime: {
            ...state.editView.overtime,
            status: false,
          },
          referralBonus: {
            ...state.editView.referralBonus,
            status: false,
          },
          comments: {
            ...state.editView.comments,
            status: false,
          },
        },
      };

// Default
    case resourcesActions.GET_RESOURCE_REFERRAL_BONUS_SUCCESS:
    case resourcesActions.ADD_RESOURCE_REFERRAL_BONUS_SUCCESS:
      return {
        ...state,
        referralBonus: {
          data: payload,
          loading: false,
        }
      };

    case resourcesActions.REMOVE_RESOURCE_REFERRAL_BONUS_SUCCESS:
      return {
        ...state,
        referralBonus: {
          data: state.referralBonus.data.filter(bonus => bonus.id !== payload),
          loading: false,
        }
      };

    case resourcesActions.UPDATE_RESOURCE_REFERRAL_BONUS_SUCCESS:
      const referralBonuses = [...state.referralBonus.data];
      const { id, amount, status, date } = payload;

      const changedId = referralBonuses.findIndex(item => {
        return item.id === id;
      });

      referralBonuses[changedId].amount = amount;
      referralBonuses[changedId].status = status;
      referralBonuses[changedId].date = date;

      return {
        ...state,
        referralBonus: {
          ...state.referralBonus,
          data: referralBonuses,
          loading: false
        }
      };

    case resourcesActions.REFERRAL_BONUS_REQUESTS_ERROR:
      return {
        ...state,
        referralBonus: {
          ...state.referralBonus,
          loading: false,
        }
      };

    // Resource comments
    case resourcesActions.GET_RESOURCE_COMMENTS_REQUEST:
    case resourcesActions.ADD_RESOURCE_COMMENT_REQUEST:
    case resourcesActions.UPDATE_RESOURCE_COMMENT_REQUEST:
      return {
        ...state,
        editView: {
          ...state.editView,
          comments: {
            ...state.editView.comments,
            loading: true,
          },
        },
        comments: {
          ...state.comments,
          loading: true,
        }
      };

    case resourcesActions.GET_RESOURCE_COMMENTS_SUCCESS:
    case resourcesActions.ADD_RESOURCE_COMMENT_SUCCESS:
      return {
        ...state,
        comments: {
          data: payload,
          loading: false,
        },
        editView: {
          ...state.editView,
          comments: {
            ...state.editView.comments,
            loading: false,
          },
        },
      };

    case resourcesActions.REMOVE_RESOURCE_COMMENT_SUCCESS:
      return {
        ...state,
         editView: {
          ...state.editView,
          comments: {
            ...state.editView.comments,
            loading: false,
          },
        },
        comments: {
          data: state.comments.data.filter(comment => comment.id !== payload.commentId),
          loading: false,
        }
      };

    case resourcesActions.COMMENTS_REQUESTS_ERROR:
      return {
        ...state,
         editView: {
          ...state.editView,
          comments: {
            ...state.editView.comments,
            loading: false,
          },
        },
        comments: {
          ...state.comments,
          loading: false,
        }
      };

    case resourcesActions.GET_RESOURCES_LIST:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: true,
        },
      }

    case resourcesActions.GET_RESOURCES_LIST_SUCCESS:
      return {
        ...state,
        resources: {
          list: payload.resources,
          pagination: payload.pagination,
          loading: false,
        },
      }

    case resourcesActions.GET_RESOURCES_LIST_ERROR:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
        },
      }
    case resourcesActions.RESET_STATE:
      return {
        ...getInitialState()
      }

    // Default
    default:
      return state;
  }
}
