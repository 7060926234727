import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import resourceActions from 'redux/resources/resource/actions';
import tableResource from 'redux/resources/tableResource/actions';
import technologyActions from 'redux/technologies/actions';
import projectsActions from 'redux/projects/actions';
import locationActions from 'redux/location/actions';
import superFetch from 'helpers/superFetch';
import { apiRequest } from 'redux/helpers';
import getDataAsUrl from 'helpers/fileReader';
import { CONTENT_TYPES, ENDPOINTS } from 'consts';
import { resolveImgUrl } from 'helpers/urlSync';
import openNotification from 'components/Notification';
import levelActions from 'redux/levels/actions';
import { onSuccessUpload } from 'helpers/utility';
import tableActions from '../../../components/NewTable/redux/actions';

function* uploadAvatar() {
  yield takeEvery(resourceActions.UPLOAD_AVATAR, function* ({ payload }) {
    const result = yield call(getDataAsUrl, payload.file);

    if (!result) {
      yield put(resourceActions.uploadAvatarError());
    } else {
      yield put(
        resourceActions.uploadAvatarSuccess({
          fileUrl: result,
          file: payload.file,
        }),
      );
    }
  });
}

function* getInitialState() {
  yield takeEvery(resourceActions.GET_INITIAL_REQUEST, function* ({ payload }) {
    const { withoutProjects, resourceId } = payload;
    const resource = yield apiRequest(
      superFetch.get,
      `${ENDPOINTS.RESOURCE_AND}${resourceId}`,
    );
    resource.avatar = resource.avatar ? resolveImgUrl(resource.avatar) : '';
    const roles = yield apiRequest(superFetch.get, `${ENDPOINTS.RESOURCE_ROLES}`);
    const locations = yield apiRequest(superFetch.get, ENDPOINTS.LOCATION);
    const technologies = yield apiRequest(superFetch.get, `${ENDPOINTS.TECHNOLOGY}?limit=all`);
    const techLvl = yield apiRequest(superFetch.get, `${ENDPOINTS.TECH_LVL}?limit=all`);
    const englishLvl = yield apiRequest(superFetch.get, `${ENDPOINTS.ENG_LVL}?limit=all`);
    const projectsList = yield apiRequest(
      superFetch.get,
      `${ENDPOINTS.PROJECTS}?limit=all&sort=title:asc`,
    );

    if (techLvl.errors || englishLvl.errors) {
      if (techLvl.errors) {
        yield openNotification('error', 'Error', techLvl.errors);
      } else {
        yield openNotification('error', 'Error', englishLvl.errors);
      }
    } else {
      yield put(levelActions.getTechLevelsSuccess(techLvl));
      yield put(levelActions.getEnglishLevelsSuccess(englishLvl));
    }

    if (locations.errors) {
      yield put(locationActions.getLocationError());
      yield openNotification('error', 'Error', locations.errors);
    } else {
      yield put(locationActions.getLocationSuccess(locations));
    }

    if (technologies.errors) {
      yield put({
        type: technologyActions.GET_TECHNOLOGIES_ERROR,
        payload: technologies.errors,
      });
      yield openNotification('error', 'Error', technologies.errors);
    } else {
      yield put(
        technologyActions.getTechnologiesSuccess(
          technologies.technologies,
          technologies.pagination,
        ),
      );
    }

    if (resource.errors || roles.errors) {
      if (resource.errors) {
        yield put(resourceActions.getError(resource.errors));
        yield openNotification('error', 'Error', resource.errors);
      } else {
        yield put(resourceActions.getError(roles.errors));
        yield openNotification('error', 'Error', roles.errors);
      }
    } else {
      yield put(resourceActions.getSuccess(resource));
      yield put({
        type: tableResource.GET_ROLES_SUCCESS,
        payload: {
          roles,
        },
      });
    }
    if (!withoutProjects) {
      if (projectsList.errors) {
        yield put({
          type: projectsActions.GET_PROJECTS_LIST_ERROR,
          payload: projectsList.errors,
        });
        yield openNotification('error', 'Error', projectsList.errors);
      } else {
        yield put(
          projectsActions.getProjectsListSuccess({
            projectsList: projectsList.projects,
            pagination: projectsList.pagination,
          }),
        );
      }
    }
  });
}

function* getResource() {
  yield takeEvery(resourceActions.GET_REQUEST, function* ({ payload }) {
    const projectsList = yield apiRequest(superFetch.get, `${ENDPOINTS.PROJECTS}/simpleList`);

    let data = yield apiRequest(superFetch.get, `${ENDPOINTS.RESOURCE_AND}${payload.id}`);
    data.avatar = data.avatar ? resolveImgUrl(data.avatar) : '';
    data = {
      ...data,
      projectsList: projectsList.projects,
    }

    if (data.errors) {
      yield put(resourceActions.getError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceActions.getSuccess(data));
    }
  });
}

function* addTechnologyRequest() {
  yield takeEvery(resourceActions.UPDATE_TECHNOLOGIES_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.RESOURCE_AND}${payload.resourceId}${ENDPOINTS.UPDATE_RESOURCE_TECHNOLOGIES}`,
      payload.body,
    );
    if (data.errors) {
      yield put(resourceActions.getError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: resourceActions.UPDATE_TECHNOLOGIES_SUCCESS,
        payload: data,
      });
    }
  });
}

function* editResourceRequest(profileData, id, modalName, fileObj) {
  let url = '';

  switch (modalName) {
    case 'hire':
      url = `${ENDPOINTS.RESOURCE_AND}${id}/hireInfo`;
      break;
    case 'other':
      url = `${ENDPOINTS.RESOURCE_AND}${id}/otherInfo`;
      break;
    case 'contacts':
      url = `${ENDPOINTS.RESOURCE_AND}${id}/contactInfo`;
      break;
    case 'vacation':
      url = `${ENDPOINTS.RESOURCE_AND}${id}/vacationInfo`;
      break;
    default:
      url = `${ENDPOINTS.RESOURCE_AND}${id}/mainInfo`;
  }

  if (fileObj.file) {
    yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.RESOURCE_AND}${id}/mainInfo`,
      { avatar: fileObj.file },
      CONTENT_TYPES.MULTIPART_DATA,
    );
    onSuccessUpload();
  }

  const resource = yield apiRequest(
    superFetch.patch,
    url,
    profileData,
  );

  if (resource.errors) {
    yield put(resourceActions.editError({ resource, modalName }));
    yield openNotification('error', 'Error', resource.errors);
  } else {
    resource.avatar = resource.avatar ? resolveImgUrl(resource.avatar) : '';
    if (fileObj.file) {
      resource.avatar = fileObj.fileUrl;
      yield put(resourceActions.resetFile());
    }
    if (modalName === 'vacation') {
      yield put(resourceActions.getResourceContractsRequest(id));
    }
    yield put(resourceActions.editSuccess({ resource, modalName }));
  }
}

function* submitUpdates() {
  yield takeEvery(resourceActions.SUBMIT_UPDATES, function* ({ payload }) {
    const { file, resource, fileUrl } = yield select(state => state.Resource);
    const fileObj = {
      file,
      fileUrl,
    };
    yield call(editResourceRequest, payload.body, resource.id, payload.modalName, fileObj);
  });
}

function* getVacationInfo() {
  yield takeEvery(resourceActions.GET_RESOURCE_VACATION_REQUEST, function* ({id}){

    const queryUrl = `${ENDPOINTS.RESOURCE_AND}${id}/vacationInfo`;
    const data = yield apiRequest(
        superFetch.get,
        queryUrl
    )

    if (data.errors) {
      yield put(resourceActions.getVacationInfoError())
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceActions.getVacationInfoSuccess(data))
    }
  })
}

function* updateResourceProject() {
  yield takeEvery(resourceActions.UPDATE_PROJECT_REQUEST, function* ({ payload }) {

    let data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.RESOURCE_AND}${payload.resourceId}${ENDPOINTS.UPDATE_RESOURCE_PROJECTS}`,
      payload.data,
    );
    data = {
      ...data,
    }

    if (data.errors) {
      yield put(resourceActions.getError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      data.avatar = data.avatar ? resolveImgUrl(data.avatar) : '';
      yield put(resourceActions.updateResourceProjectSuccess(data));
      yield put(resourceActions.getResourcesHistoryDetailPageRequest(payload.resourceId));
    }
  });
}

function* getResourceContracts() {
  yield takeEvery(resourceActions.GET_RESOURCE_CONTRACTS_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(superFetch.get,
      `${ENDPOINTS.RESOURCE_AND}${payload.id}${ENDPOINTS.CONTRACTS}`);

    if (data.errors) {
      yield put(resourceActions.getContractsError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceActions.getContractsSuccess(data));
    }
  });
}

function* getResourceEvaluationsRequest() {
  yield takeEvery(resourceActions.GET_RESOURCE_EVALUATIONS_REQUEST, function* ({ payload }) {
    const queryUrl = `${ENDPOINTS.RESOURCE}/${payload.id}/evaluation`;
    const data = yield apiRequest(
      superFetch.get,
      queryUrl,
    );

    if (data.errors) {
      yield put(resourceActions.getResourceEvaluationsError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceActions.getResourceEvaluationsSuccess(data));
      yield put(tableActions.setPagination(data.pagination));
    }
  });
}

function* getResourceTechnologyEvaluationsRequest() {
  yield takeEvery(resourceActions.GET_RESOURCE_TECHNOLOGY_EVALUATIONS_REQUEST, function* ({ payload }) {
    const queryUrl = `${ENDPOINTS.RESOURCE}/${payload.id}/techEvaluations`;
    const data = yield apiRequest(
      superFetch.get,
      queryUrl
    );

    if (data.errors) {
      yield put(resourceActions.getResourceTechnologyEvaluationError());
      yield openNotification('error', 'Error', data.errors)
    } else {
      yield put(resourceActions.getResourceTechnologyEvaluationSuccess(data))
    }
  })
}

function* getResourceEnglishEvaluationsRequest() {
  yield takeEvery(resourceActions.GET_RESOURCE_ENGLISH_EVALUATIONS_REQUEST, function* ({ payload }) {
    const queryUrl = `${ENDPOINTS.RESOURCE}/${payload.id}/engEvaluations`;
    const data = yield apiRequest(
      superFetch.get,
      queryUrl
    );

    if (data.errors) {
      yield put(resourceActions.getResourceEnglishEvaluationError());
      yield openNotification('error', 'Error', data.errors)
    } else {
      yield put(resourceActions.getResourceEnglishEvaluationSuccess(data))
    }
  })
}

function* getResourcesHistoryDetailPageRequest() {
  yield takeEvery(resourceActions.GET_RESOURCE_HISTORY_DETAIL_PAGE_REQUEST,
    function* ({ payload }) {
    const queryUrl = `${ENDPOINTS.RESOURCE}/${payload.id}/projectHistory`;
    const data = yield apiRequest(
      superFetch.get,
      queryUrl,
    );

    if (data.errors) {
      yield put(resourceActions.getResourcesHistoryDetailPageError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceActions.getResourcesHistoryDetailPageSuccess(data));
      yield put(tableActions.setPagination(data.pagination));
    }
  });
}

function* addContractRequest() {
  yield takeEvery(resourceActions.ADD_RESOURCE_CONTRACTS_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(
      superFetch.post,
      `${ENDPOINTS.RESOURCE_AND}${payload.id}${ENDPOINTS.CONTRACTS}`,
      payload.body,
    );

    if (data.errors) {
      yield put(resourceActions.getError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put (resourceActions.getVacationInfoRequest(payload.id))
      yield put({
        type: resourceActions.ADD_RESOURCE_CONTRACTS_SUCCESS,
        payload: data,
      });
    }
  });
}

function* removeContractRequest() {
  yield takeEvery(resourceActions.REMOVE_RESOURCE_CONTRACTS_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(
      superFetch.delete,
      `${ENDPOINTS.RESOURCE_AND}${payload.id}${ENDPOINTS.CONTRACTS}/${payload.contractId}`,
    );

    if (data.errors) {
      yield put(resourceActions.getError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: resourceActions.REMOVE_RESOURCE_CONTRACTS_SUCCESS,
        payload: payload.contractId,
      });
      yield put (resourceActions.getVacationInfoRequest(payload.id))
    }
  });
}

function* updateResourceContractRequest() {
  yield takeEvery(resourceActions.UPDATE_RESOURCE_CONTRACT_REQUEST, function* ({ payload }) {
    const { Resource } = yield select();
    const { resource: { id } } = Resource;

    const body = {
      text: payload?.text,
      startDate: payload?.startDate,
      endDate: payload?.endDate,
      paidVacationDays: payload?.paidVacationDays,
      unpaidVacationDays: payload?.unpaidVacationDays,
    };

    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.RESOURCE_AND}${id}${ENDPOINTS.CONTRACTS}/${payload.id}`,
      body,
    );

    if (data.errors) {
      yield put(resourceActions.getError(data.errors));
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put (resourceActions.getVacationInfoRequest(id))
      yield put(resourceActions.updateResourceContractSuccess(data));
    }
  });
}

function* getResourceSalary() {
  let data = [];
  yield takeEvery(resourceActions.GET_RESOURCE_SALARY_REQUEST, function* ({ payload }) {
    const { userDetails: { locations } } = yield select(state => state.Auth);

    if (locations.includes(payload.object.locationId)) {
      data = yield apiRequest(superFetch.get,
        `${ENDPOINTS.RESOURCE_AND}${payload.object.resourceId}${ENDPOINTS.SALARY}`);
    }

    if (data.errors) {
      yield put(resourceActions.salaryRequestsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceActions.getResourceSalarySuccess(data));
    }
  });
}

function* addSalaryRequest() {
  yield takeEvery(resourceActions.ADD_RESOURCE_SALARY_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(
      superFetch.post,
      `${ENDPOINTS.RESOURCE_AND}${payload.id}${ENDPOINTS.SALARY}`,
      payload.body,
    );

    if (data.errors) {
      yield put(resourceActions.salaryRequestsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceActions.addResourceSalarySuccess(data));
    }
  });
}

function* removeSalaryRequest() {
  yield takeEvery(resourceActions.REMOVE_RESOURCE_SALARY_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(
      superFetch.delete,
      `${ENDPOINTS.RESOURCE_AND}${payload.resourceId}${ENDPOINTS.SALARY}/${payload.salaryId}`,
    );

    if (data.errors) {
      yield put(resourceActions.salaryRequestsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put({
        type: resourceActions.REMOVE_RESOURCE_SALARY_SUCCESS,
        payload: payload.salaryId,
      });
    }
  });
}

function* updateResourceSalaryRequest() {
  yield takeEvery(resourceActions.UPDATE_RESOURCE_SALARY_REQUEST, function* ({ payload }) {

    const { Resource } = yield select();
    const { resource: { id } } = Resource;

    const body = {
      amount: payload?.amount,
      startDate: payload?.startDate,
      endDate: payload?.endDate,
    };

    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.RESOURCE_AND}${id}${ENDPOINTS.SALARY}/${payload.id}`,
      body,
    );

    if (data.errors) {
      yield put(resourceActions.salaryRequestsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceActions.updateResourceSalarySuccess(data));
    }
  });
}

function* getResourceSigningBonus() {
  let data = [];
  yield takeEvery(resourceActions.GET_RESOURCE_SIGNING_BONUS_REQUEST, function* ({ payload }) {
    const { userDetails: { locations } } = yield select(state => state.Auth);

    if (locations.includes(payload.object.locationId)) {
      data = yield apiRequest(superFetch.get,
        `${ENDPOINTS.RESOURCE_AND}${payload.object.resourceId}${ENDPOINTS.SIGNING_BONUS}`);
    }

    if (data.errors) {
      yield put(resourceActions.bonusRequestsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceActions.getResourceSigningBonusSuccess(data));
    }
  });
}

function* addSigningBonusRequest() {
  yield takeEvery(resourceActions.ADD_RESOURCE_SIGNING_BONUS_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(
      superFetch.post,
      `${ENDPOINTS.RESOURCE_AND}${payload.id}${ENDPOINTS.SIGNING_BONUS}`,
      payload.body,
    );

    if (data.errors) {
      yield put(resourceActions.bonusRequestsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceActions.addResourceSigningBonusSuccess(data));
    }
  });
}

function* removeSigningBonusRequest() {
  yield takeEvery(resourceActions.REMOVE_RESOURCE_SIGNING_BONUS_REQUEST, function* ({ payload }) {
    const data = yield apiRequest(
      superFetch.delete,
      `${ENDPOINTS.RESOURCE_AND}${payload.resourceId}${ENDPOINTS.SIGNING_BONUS}/${payload.bonusId}`,
    );

    if (data.errors) {
      yield put(resourceActions.bonusRequestsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceActions.removeResourceSigningBonusSuccess(payload.bonusId));
    }
  });
}

function* updateResourceSigningBonusRequest() {
  yield takeEvery(resourceActions.UPDATE_RESOURCE_SIGNING_BONUS_REQUEST, function* ({ payload }) {
    const { Resource } = yield select();
    const { resource: { id } } = Resource;

    const body = {
      amount: payload?.amount,
      date: payload?.date,
      status: payload?.status,
      comment: payload?.comment,
    };
    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.RESOURCE_AND}${id}${ENDPOINTS.SIGNING_BONUS}/${payload.id}`,
      body,
    );

    if (data.errors) {
      yield put(resourceActions.bonusRequestsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceActions.updateResourceSigningBonusRequestSuccess(data));
    }
  });
}

function* getResourceReferralBonus() {
  let data = [];
  yield takeEvery(resourceActions.GET_RESOURCE_REFERRAL_BONUS_REQUEST, function* ({ payload }) {
    const { userDetails: { locations } } = yield select(state => state.Auth);

    if (locations.includes(payload.object.locationId)) {
      data = yield apiRequest(superFetch.get,
        `${ENDPOINTS.RESOURCE_AND}${payload.object.resourceId}${ENDPOINTS.REFERRAL_BONUS}`);
    }

    if (data.errors) {
      yield put(resourceActions.referralBonusRequestsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceActions.getResourceReferralBonusSuccess(data));
    }
  });
}

function* addReferralBonusRequest() {
  yield takeEvery(resourceActions.ADD_RESOURCE_REFERRAL_BONUS_REQUEST, function* ({ payload }) {
    const { id, body } = payload;

    const data = yield apiRequest(
      superFetch.post,
      `${ENDPOINTS.RESOURCE_AND}${id}${ENDPOINTS.REFERRAL_BONUS}`,
      body,
    );

    if (data.errors) {
      yield put(resourceActions.referralBonusRequestsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceActions.addResourceReferralBonusSuccess(data));
    }
  });
}

function* removeReferralBonusRequest() {
  yield takeEvery(resourceActions.REMOVE_RESOURCE_REFERRAL_BONUS_REQUEST, function* ({ payload }) {
    const { resourceId, bonusId } = payload;

    const data = yield apiRequest(
      superFetch.delete,
      `${ENDPOINTS.RESOURCE_AND}${resourceId}${ENDPOINTS.REFERRAL_BONUS}/${bonusId}`,
    );

    if (data.errors) {
      yield put(resourceActions.referralBonusRequestsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceActions.removeResourceReferralBonusSuccess(bonusId));
    }
  });
}

function* updateResourceReferralBonusRequest() {
  yield takeEvery(resourceActions.UPDATE_RESOURCE_REFERRAL_BONUS_REQUEST, function* ({ payload }) {
    const { Resource } = yield select();
    const { resource: { id } } = Resource;
    const { amount, status, date, invitedResourceId, typeOfPayment } = payload;

    const body = {
      amount,
      status,
      date,
      invitedResourceId,
      typeOfPayment,
    };

    const data = yield apiRequest(
      superFetch.patch,
      `${ENDPOINTS.RESOURCE_AND}${id}${ENDPOINTS.REFERRAL_BONUS}/${payload.id}`,
      body,
    );

    if (data.errors) {
      yield put(resourceActions.referralBonusRequestsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceActions.updateResourceReferralBonusRequestSuccess(data));
    }
  });
}

function* getResourceComments() {
  yield takeEvery(resourceActions.GET_RESOURCE_COMMENTS_REQUEST, function* ({ payload }) {
    const { id } = payload;

    const data = yield apiRequest(superFetch.get,
      `${ENDPOINTS.RESOURCE_AND}${id}${ENDPOINTS.COMMENT}`);

    if (data.errors) {
      yield put(resourceActions.commentsRequestsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceActions.getResourceCommentsSuccess(data));
    }
  });
}

function* addCommentRequest() {
  yield takeEvery(resourceActions.ADD_RESOURCE_COMMENT_REQUEST, function* ({ payload }) {
    const { Profile } = yield select();
    const { profile: { id } } = Profile;
    const { body } = payload;

    const data = yield apiRequest(
      superFetch.post,
      `${ENDPOINTS.RESOURCE_AND}${payload.id}${ENDPOINTS.COMMENT}`,
      {
        ...body,
        commentOwnerId: id,
      },
    );

    if (data.errors) {
      yield put(resourceActions.commentsRequestsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceActions.addResourceCommentSuccess(data));
      yield openNotification('success', 'Success', 'Comment was added.');
    }
  });
}

function* removeCommentRequest() {
  yield takeEvery(resourceActions.REMOVE_RESOURCE_COMMENT_REQUEST, function* ({ payload }) {
    const { resourceId, commentId } = payload;

    const data = yield apiRequest(
      superFetch.delete,
      `${ENDPOINTS.RESOURCE_AND}${resourceId}${ENDPOINTS.COMMENT}/${commentId}`,
    );

    if (data.errors) {
      yield put(resourceActions.commentsRequestsError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceActions.removeResourceCommentSuccess(payload));
    }
  });
}

function* getResources() {
  yield takeEvery(resourceActions.GET_RESOURCES_LIST, function* () {
    const url = `${ENDPOINTS.RESOURCE}?limit=all&statusFilter=hired`;
    const data = yield apiRequest(superFetch.get, url);

    if (data.errors) {
      yield put(resourceActions.getResourcesError());
      yield openNotification('error', 'Error', data.errors);
    } else {
      yield put(resourceActions.getResourcesSuccess(data));
    }
  });
}

export default function* rootSaga() {
  yield all([
    getResource(),
    uploadAvatar(),
    submitUpdates(),
    addTechnologyRequest(),
    getInitialState(),
    updateResourceProject(),
    addContractRequest(),
    getResourceContracts(),
    removeContractRequest(),
    updateResourceContractRequest(),
    getResourceEvaluationsRequest(),
    getResourceTechnologyEvaluationsRequest(),
    getResourceEnglishEvaluationsRequest(),
    getResourcesHistoryDetailPageRequest(),
    getResourceSalary(),
    addSalaryRequest(),
    removeSalaryRequest(),
    updateResourceSalaryRequest(),
    getResourceSigningBonus(),
    addSigningBonusRequest(),
    removeSigningBonusRequest(),
    updateResourceSigningBonusRequest(),
    getResourceReferralBonus(),
    addReferralBonusRequest(),
    removeReferralBonusRequest(),
    updateResourceReferralBonusRequest(),
    getResourceComments(),
    addCommentRequest(),
    removeCommentRequest(),
    getResources(),
    getVacationInfo(),
  ]);
}
