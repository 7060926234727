const clientDetailsActions = {
  GET_CLIENT_DETAILS_REQUEST: 'GET_CLIENT_DETAILS_REQUEST',
  GET_CLIENT_DETAILS_SUCCESS: 'GET_CLIENT_DETAILS_SUCCESS',
  GET_CLIENT_DETAILS_ERROR: 'GET_CLIENT_DETAILS_ERROR',

  UPDATE_CLIENT_DETAILS_REQUEST: 'UPDATE_CLIENT_DETAILS_REQUEST',
  UPDATE_CLIENT_DETAILS_SUCCESS: 'UPDATE_CLIENT_DETAILS_SUCCESS',
  UPDATE_CLIENT_DETAILS_ERROR: 'UPDATE_CLIENT_DETAILS_ERROR',

  CHANGE_BLOCK_MODE: 'CHANGE_BLOCK_MODE',
  RESET_STATE: 'RESET_STATE',

  changeBlockMode: (blockName, status) => ({
    type: clientDetailsActions.CHANGE_BLOCK_MODE,
    payload: {blockName, status}
  }),

  getClientDetailsRequest: (id) => ({
    type: clientDetailsActions.GET_CLIENT_DETAILS_REQUEST,
    id,
  }),

  getClientDetailsSuccess: (data) => ({
    type: clientDetailsActions.GET_CLIENT_DETAILS_SUCCESS,
    payload: data,
  }),

  getClientDetailsError: () => ({
    type: clientDetailsActions.GET_CLIENT_DETAILS_ERROR,
  }),

  updateClientsDetailsRequest: (blockName, data) => ({
    type: clientDetailsActions.UPDATE_CLIENT_DETAILS_REQUEST,
    payload: {blockName, data},
  }),

  updateClientsDetailsSuccess: (data, blockName) => ({
    type: clientDetailsActions.UPDATE_CLIENT_DETAILS_SUCCESS,
    payload: {data, blockName}
  }),

  updateClientsDetailsError: (blockName) => ({
    type: clientDetailsActions.UPDATE_CLIENT_DETAILS_ERROR,
    payload: {blockName},
  }),

  resetState: () => ({
    type: clientDetailsActions.RESET_STATE,
  }),
}

export default clientDetailsActions
