import React from 'react';
import { uniqBy } from 'lodash';
import { Tooltip } from 'antd';
import moment from 'moment';

export const COUNT_DAYS_BY_END_PROJECT = 15;
export const getDaysByEndProject = (endDate) => {
  const result = Math.ceil((moment(endDate) - moment(Date.now())) / (1000 * 3600 * 24));
  return (result > 0 && result < COUNT_DAYS_BY_END_PROJECT) ? result : '';
}
export const getAllResourcesByHistory = allData => {
  const allResourcesByHistory = allData.resourceProjectHistories.map(elem => elem.resource);
  const filteredResources = uniqBy(allResourcesByHistory,"id");
  return filteredResources.map(elem => ({
    ...elem,
    fullName: `${elem?.lastName} ${elem?.firstName}`,
  })).sort((a,b) => a.fullName > b.fullName ? 1 : -1);
}
export const getAllProjectsByHistory = allData => {
  const allProjectsByHistory = allData.resourceProjectHistories.map(elem => elem?.project);
  const filteredProjects = uniqBy(allProjectsByHistory,"id");
  const filteredProjectsWithoutNull = filteredProjects.filter(elem => elem !== null)
    .sort((a,b) => a.title > b.title ? 1 : -1);
  const object = {id:'null', title:'Without a project'};
  return [object, ...filteredProjectsWithoutNull];
}

export function getVacationDays(number) {
  if (number === 1) {
    return `${number} day`;
  }
  return `${number} days`;
}
export const getInitialsByResource = (item, text) => {
  const numberContract = text.length > 2
    ? `${text.slice(0, 2)}.. ` : text;
  return (
    <Tooltip
      title={`${item?.firstName.slice(0, 1)}${item?.lastName.slice(0, 1)}${text}`}
    >
      {` ${item?.firstName.slice(0, 1)}${item?.lastName.slice(0, 1)}${numberContract}:`}
    </Tooltip>
  );
}
export const getPeriodByContract = (elem) => {
  return `${moment(elem?.startDate).format('MM/DD/YY')} -
      ${moment(elem?.endDate).format('MM/DD/YY')}`;
};

export const getPlacement = description => {
  return description?.length > 100 ? 'right' : 'top';
};

export const getNumbersInArray = array => {
  return array?.map(elem => Number(elem));
}
